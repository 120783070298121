<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta
      :content="title"
      property="og:title"
    />
    <Meta
      v-for="(metaTag, index) of metaTags"
      :key="`meta-tag-${index}`"
      :content="metaTag.Content"
      :name="metaTag.Name"
    />
    <Style
      :children="`:root { ${theme} }`"
      type="text/css"
    />
  </Head>
  <header-page v-if="!route.meta.HideHeader" />

  <NuxtLoadingIndicator
    :color="appConfig.VueSettingsPreRun.Theme.PrimaryBackgroundColor"
    :height="10"
  />
  <common-breadcrumbs />
  <menu-seo-text
    addititional-text
    :text="additionalSeoText"
  />
  <main v-on-slow-performance="{ onSlowContent: slowContentReport, threshold: 500 }">
    <NuxtPage :page-key="() => route.fullPath" />
  </main>

  <menu-seo-text
    is-main-page
    :home-page-content="homePage"
    :text="seoText"
  />

  <ClientOnly>
    <lazy-popup-observer />
    <lazy-clientside-general />
    <footer-page v-if="!route.meta.HideFooter" />
  </ClientOnly>
  <div
    v-if="isLoading"
    id="v-app-loader"
    style="backdrop-filter: blur(10px)"
  >
    <div class="v-loader-wrapper">
      <arora-loader />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MetaTag } from '~types/preloadRequests'

import {
  type ISO,
  RegionalSettings,
  type SlowContentData,
  useBackendCompatibleGuid,
  vOnSlowPerformance
} from '@arora/common'

import type { RouteLocationNormalized } from 'vue-router'

import gsap from 'gsap'
import mitt from 'mitt'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sbjs from 'sourcebuster'
import { Guid } from '~api/consts'
import createGlobalTheme from '~api/theme'
import GeneralMetric from '~integrations/metric/general'
import { initWebApp } from '~integrations/webapp/general'

const accountStore = useAccountStore()
const addressStore = useAddressStore()
const clientStore = useClientStore()
const restaurantStore = useRestaurantStore()
const mapsStore = useMapsStore()
const menuStore = useMenuStore()
const pageStore = usePageStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const nuxtApp = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { translate } = useI18nSanitized()
const { generateGUID } = useBackendCompatibleGuid()

const isLoading = ref<boolean>(true)

nuxtApp.hook('app:mounted', () => {
  gsap.to(`#v-app-loader`, {
    backdropFilter: 'blur(0px)',
    duration: 0.15,
    onComplete: () => {
      isLoading.value = false

      if (!import.meta.dev && import.meta.client)
        navigator.sendBeacon(
          `/api/event-time?type=loader-gone&time=${Math.round(Date.now() - window.startTime)}`
        )

      window.YandexRotorSettings.IsLoaded = true
    },
    opacity: 0
  })
})

function slowContentReport(data: SlowContentData): void {
  console.warn('Slow resource load:', data)
}

const theme = ref<string>(createGlobalTheme(appConfig.VueSettingsPreRun, !!route.meta.UseAlternateTheme))

restaurantStore.CurrencySettings = RegionalSettings.get(appConfig.VueSettingsPreRun.Currency as ISO)
const { makeDefaultOption } = useProduct()

function makeEmoji(text: string): string {
  if (text.includes('&#') && !text.includes('&#x')) {
    const regexpResults = /&#.*;/.exec(text)

    let result = text

    if (!regexpResults) return text

    for (const regexp of regexpResults) {
      result = result.replaceAll(
        regexp,
        String.fromCodePoint(Number.parseInt(regexp.replaceAll('&#', '').replaceAll(';', '')))
      )
    }

    return result
  }

  return text
}

function getPageTitle(route: RouteLocationNormalized): string {
  if (appConfig.SEOPreloadData.UrlTitleInfo[route.path])
    return makeEmoji(appConfig.SEOPreloadData.UrlTitleInfo[route.path].Title)

  if (route.meta.Title) return makeEmoji(route.meta.Title as string)

  switch (route.path) {
    case appConfig.VueSettingsPreRun.Links.AboutLink: {
      return translate('defaultTexts.aboutUs')
    }
    case appConfig.VueSettingsPreRun.Links.ActionsLink: {
      return translate('defaultTexts.actions')
    }
    case appConfig.VueSettingsPreRun.Links.AgreementLink: {
      return translate('defaultTexts.agreement')
    }
    case appConfig.VueSettingsPreRun.Links.ArticlesLink: {
      return translate('defaultTexts.articles')
    }
    case appConfig.VueSettingsPreRun.Links.CartFinalStep: {
      return translate('defaultTexts.mainPage')
    }
    case appConfig.VueSettingsPreRun.Links.CartFirstStep:
    case appConfig.VueSettingsPreRun.Links.CartSecondStep: {
      return translate('defaultTexts.cart')
    }
    case appConfig.VueSettingsPreRun.Links.ContactsLink: {
      return translate('defaultTexts.contacts')
    }
    case appConfig.VueSettingsPreRun.Links.DeliveryLink: {
      return translate('defaultTexts.delivery')
    }
    case appConfig.VueSettingsPreRun.Links.FeedbackLink: {
      return translate('defaultTexts.feedback')
    }
    case appConfig.VueSettingsPreRun.Links.MainLink: {
      return translate('defaultTexts.mainPage')
    }
    case appConfig.VueSettingsPreRun.Links.PartnershipLink: {
      return translate('defaultTexts.partnership')
    }
    case appConfig.VueSettingsPreRun.Links.PayRulesLink: {
      return translate('defaultTexts.paymentRules')
    }
    case appConfig.VueSettingsPreRun.Links.PersonalCabinetLink: {
      return translate('defaultTexts.personalCabinet')
    }
    case appConfig.VueSettingsPreRun.Links.PersonalDataLink: {
      return translate('defaultTexts.personalData')
    }
    case appConfig.VueSettingsPreRun.Links.PointsLink: {
      return translate('defaultTexts.pointsPage')
    }
    case appConfig.VueSettingsPreRun.Links.RestaurantSingleLink:
    case appConfig.VueSettingsPreRun.Links.RestaurantsLink: {
      return translate('defaultTexts.restaurants')
    }
    case appConfig.VueSettingsPreRun.Links.VacancyLink: {
      return translate('defaultTexts.vacancies')
    }
    default: {
      return translate('defaultTexts.mainPage')
    }
  }
}

const title = ref<string | undefined>(getPageTitle(route))

onBeforeMount(async () => {
  const ssid = useCookie(appConfig.SSIDName, {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  })

  if (!ssid.value) ssid.value = generateGUID()

  onActivityMapEvent()

  if (import.meta.client) {
    const promises: PromiseLike<unknown>[] =
      route.name === 'index' ? [menuStore.getRelated(Guid.Empty), pageStore.initActions()] : []
    if (route.meta.HideHeader && route.meta.HideFooter) document.body.classList.add('v-fullscreen')

    window.emitter = mitt()

    if (promises.length > 0) {
      await Promise.all(promises)
    }
  }
})

function onActivityMapEvent(): void {
  for (const product of appConfig.Products) {
    if (!menuStore.SelectedModifiersPerProduct.get(product.ID))
      menuStore.SelectedModifiersPerProduct.set(product.ID, {})

    if (Object.keys(product.Options).length > 0) makeDefaultOption(product)
  }
}

watch(
  () => menuStore.ActivityMapLoaded,
  (newValue, oldValue) => {
    if (newValue !== oldValue && newValue) {
      onActivityMapEvent()
    }
  }
)

onMounted(async () => {
  if (import.meta.client) {
    await Promise.all([
      initWebApp(),
      menuStore.refreshActivityMap(),
      accountStore.initProfile(),
      clientStore.initClientState(),
      addressStore.initTerminals(),
      mapsStore.clearAllMaps()
    ])

    //integrations init
    restaurantStore.Metrics = new GeneralMetric(
      appConfig.CurrentRestaurantId,
      appConfig.RestaurantSettingsPreRun
    )

    sbjs.init({ lifetime: 6 })
  }
})

const seoText = ref<string | undefined>(route.meta.SeoText as string | undefined)
const additionalSeoText = ref<string | undefined>(route.meta.AdditionalSeoText as string | undefined)

const homePage = ref<boolean>(route.path === appConfig.VueSettingsPreRun.Links.MainLink)

const metaTags = ref<MetaTag[]>(route.meta.MetaTags as MetaTag[])

router.beforeEach((to) => {
  try {
    title.value = getPageTitle(to)
    metaTags.value = [...((to.meta.MetaTags ?? []) as MetaTag[])]
  } catch (error) {
    console.error('router.beforeEach global error', error)
  }
})

router.afterEach((to) => {
  try {
    seoText.value = to.meta.SeoText as string | undefined
    additionalSeoText.value = to.meta.AdditionalSeoText as string | undefined

    homePage.value = to.path === appConfig.VueSettingsPreRun.Links.MainLink

    theme.value = createGlobalTheme(appConfig.VueSettingsPreRun, !!to.meta.UseAlternateTheme)

    if (popupStore.popupComponent) {
      popupStore.closePopup()
    }

    if (import.meta.client && to.meta.HideHeader && to.meta.HideFooter) {
      document.body.classList.add('v-fullscreen')
    } else {
      document.body.classList.remove('v-fullscreen')
    }

    mapsStore.clearAllMaps()
  } catch (error) {
    console.error('router.afterEach global error', error)
  }
})
</script>

<style lang="scss">
#v-app-loader {
  position: fixed;
  z-index: 999998;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .v-loader-wrapper {
    z-index: 999999;

    aspect-ratio: 1/1;
    height: 85vmin;
  }
}

.v-fullscreen {
  background: #222;
}
</style>
